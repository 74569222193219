@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Krona+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h4 {
  padding: 0;
  margin: 0;
}

.text-xSmall {
  font-size: 0.58em !important;
}

.text-small {
  font-size: 0.7em !important;
}

.text-medium {
  font-size: 0.8em !important;
}

.text-large {
  font-size: 1.25em !important;
}

.text-xLarge {
  font-size: 1.5em !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.text-black {
  color: black;
}

.font-opensans {
  font-family: "Open Sans", sans-serif !important;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif !important;
  font-weight: normal;
}

.font-poppins {
  font-family: "Poppins", sans-serif !important;
}

.font-raleway {
  font-family: "Raleway", sans-serif !important;
}

.font-roboto {
  font-family: "Roboto", sans-serif !important;
}

.hyphen-auto {
  hyphens: auto;
}

/* overriding ant styles (pagination) */

.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-pagination-item-link:focus {
  border-color: #fd602c !important;
}

.ant-pagination-item-link {
  color: rgba(53, 48, 48, 0.774) !important;
}

.ant-pagination-item-link:hover {
  border-color: #fd602c !important;
}

.ant-pagination-item-active {
  border-color: #fd602c !important;
  background-color: #fd602c !important;
  color: white !important;
}

.ant-pagination-item:hover {
  border-color: #fd602c !important;
}

/* overriding end */

.cursor-pointer {
  cursor: pointer;
}

.color__primary {
  color: #fd602c !important;
}

.title__primary {
  color: #333e50 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.main__title {
  font-size: 50px;
}

.uploadImage__box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  background-color: whitesmoke;
}
