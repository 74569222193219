.fHeader__wrapper {
  font-family: "Montserrat", sans-serif !important;
  background: #fd602c !important;
  font-size: 14px !important;
}

.fHeader__rightText {
  color: white;
  transition: 300ms !important;
  &:hover {
    cursor: pointer;
    color: black !important;
    transition: 300ms !important;
  }
}

@media only screen and (max-width: 768px) {
}
