.servicecard__wrapper {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
  }
  
  .servicecard__price {
    font-weight: 500;
    font-size: 1.1em;
    color: black;
  }
  
  .servicecard__title {
    margin: 0;
    padding: 0;
    font-weight: 600;
    color: #fd602c;
    font-size: 20px;
  }
  