.eventlist__search-lable {
  font-size: 1em;
}

.eventlist__search-input {
  // min-width: 20rem;
  // max-width: 20rem;
  width: 100%;
}

.eventlist__search-resultbox-item-wrapper {
  border-bottom: 1px solid rgb(255, 255, 255);
  padding-bottom: 0.5rem;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 999 !important;
  
  &:hover {
    color: #fd602c;
    transition: all 0.3s;
  }
}

.eventlist__search-resultbox {
  margin-top: 0.3rem;
  top: 100%;
  width: 100%;
  padding: 0.4rem;
  background-color: white;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 999 !important;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.527);
}

.eventlist__search-resultbox-img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid rgba(128, 128, 128, 0.164);
}
