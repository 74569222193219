.services__wrapper{
    min-height: 50vh;
    background-image: url("../img/03.webp");
    background-size: cover;
    background-position: center;
    position: relative;
}
.service__title {
    color: white;
    font-weight: normal;
    background-color: rgba(0, 0, 0, 0.363);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sevices__search {
    border: 1px solid rgba(0, 0, 0, 0.534);
    padding: 0.5rem 1.5rem;
    // border-radius: 1rem;
    width: 55%;
    &:focus {
      outline: none !important;
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
.search-button{
      padding: 0.5rem 1.5rem;
      height: 39px;
    }