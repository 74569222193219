.footer__wrapper {
  background: black;
}
.footer__wrapperBott {
  background: #1a1a1a;
  color: #727272;
  font-family: "Montserrat", sans-serif !important;
}
.footer__frstRow {
  color: #727272;
}
.footer__headings {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
}
.footer__recentPostsWrapper {
  font-family: "Montserrat", sans-serif !important;

  &:hover {
    cursor: pointer;
  }
}
.footer__headings__style {
  border: 1px solid white;
}
.footer__recentPostTitle {
  font-size: 18px;
}
.footer__recentPostSubTitle {
  font-size: 14px;
  color: #727272;
}
.footer__recentPostAuthor {
  font-size: 14px;
  color: #727272;
}
.footer__usefullLinks {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  margin-left: 10px;
  transition: 300ms;
  color: #727272;
  &:hover {
    cursor: pointer;
    transition: 300ms;
    color: white;
  }
}
.footer__tagBox {
  font-family: "Montserrat", sans-serif !important;
  color: #727272;
  border: 1px solid #727272;
  &:hover {
    cursor: pointer;
    transition: 400ms;
    border: 1px solid #fd602c;
    color: #fd602c;
  }
}
.footer__bottomIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #fd602c;
  &:hover {
    cursor: pointer;
  }
}

.footer__recentPostsWrapper_img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  object-position: center;
}
