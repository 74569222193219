.header__wrapper {
  font-family: "Montserrat", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__navbar {
  display: flex;
  align-items: center;
}

.header__navbarText {
  padding: 0px 10px;
  font-weight: 400;
  color: #8d77a3;
  font-size: 14px;
  transition: 300ms !important;
  &:hover {
    cursor: pointer;
    // color: #fd602c !important;
    transition: 300ms !important;

    .header__navbar-dropdown {
      transition: all 0.5s ease-in;
      display: block;
    }
  }
}
.header__navbarActive {
  // color: #fd602c !important;
}

// .header__navbar {
//   display: none !important;
// }

@media only screen and (max-width: 1126px) {
  .header__navbar {
    display: none;
  }
  .header__wrapper {
    justify-content: space-between;
  }
  .header__navbar_mob {
    display: block;
  }
}

@media only screen and (min-width: 1126px) {
  .header__navbar_mob {
    display: none;
  }
}

.header__navbar-dropdown {
  background-color: white;
  min-width: 100%;
  width: max-content;
  // border: 1px solid black;
  top: 100%;
  z-index: 100;
  padding-top: 1.25rem;
  display: none;
  transition: all 0.5s;
}

.header__navbar-dropdown-item {
  cursor: pointer;
  // padding: auto 1rem;
  transition: 0.5s;

  &:hover {
    background-color: rgba(211, 211, 211, 0.322);
    transition: 0.5s;
  }
}

.header__nav-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 888;
  background-color: white;
  // background-color: rgb(235, 234, 234);
}
