@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h4 {
  padding: 0;
  margin: 0;
}

.text-xSmall {
  font-size: 0.58em !important;
}

.text-small {
  font-size: 0.7em !important;
}

.text-medium {
  font-size: 0.8em !important;
}

.text-large {
  font-size: 1.25em !important;
}

.text-xLarge {
  font-size: 1.5em !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.text-black {
  color: black;
}

.font-opensans {
  font-family: "Open Sans", sans-serif !important;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif !important;
  font-weight: normal;
}

.font-poppins {
  font-family: "Poppins", sans-serif !important;
}

.font-raleway {
  font-family: "Raleway", sans-serif !important;
}

.font-roboto {
  font-family: "Roboto", sans-serif !important;
}

.hyphen-auto {
  -webkit-hyphens: auto;
          hyphens: auto;
}

/* overriding ant styles (pagination) */

.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-pagination-item-link:focus {
  border-color: #fd602c !important;
}

.ant-pagination-item-link {
  color: rgba(53, 48, 48, 0.774) !important;
}

.ant-pagination-item-link:hover {
  border-color: #fd602c !important;
}

.ant-pagination-item-active {
  border-color: #fd602c !important;
  background-color: #fd602c !important;
  color: white !important;
}

.ant-pagination-item:hover {
  border-color: #fd602c !important;
}

/* overriding end */

.cursor-pointer {
  cursor: pointer;
}

.color__primary {
  color: #fd602c !important;
}

.title__primary {
  color: #333e50 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.main__title {
  font-size: 50px;
}

.uploadImage__box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  background-color: whitesmoke;
}



.mainloader__wrapper{display:flex;justify-content:center;align-items:center;width:100vw;height:100vh;background:#c5cae9}.mainloader__box{width:300px;height:300px;background:#fd602c;display:flex;justify-content:center;align-items:center;box-shadow:4px 4px 20px rgba(0,0,0,.3)}.mainloader__container{height:15px;width:105px;display:flex;position:relative}.mainloader__container .mainloader__circle{width:15px;height:15px;border-radius:50%;background-color:#fff;-webkit-animation:move 500ms linear 0ms infinite;animation:move 500ms linear 0ms infinite;margin-right:30px}.mainloader__container .mainloader__circle:first-child{position:absolute;top:0;left:0;-webkit-animation:grow 500ms linear 0ms infinite;animation:grow 500ms linear 0ms infinite}.mainloader__container .mainloader__circle:last-child{position:absolute;top:0;right:0;margin-right:0;animation:grow 500ms linear 0s infinite reverse}@-webkit-keyframes grow{from{-webkit-transform:scale(0, 0);transform:scale(0, 0);opacity:0}to{-webkit-transform:scale(1, 1);transform:scale(1, 1);opacity:1}}@keyframes grow{from{-webkit-transform:scale(0, 0);transform:scale(0, 0);opacity:0}to{-webkit-transform:scale(1, 1);transform:scale(1, 1);opacity:1}}@-webkit-keyframes move{from{-webkit-transform:translateX(0px);transform:translateX(0px)}to{-webkit-transform:translateX(45px);transform:translateX(45px)}}@keyframes move{from{-webkit-transform:translateX(0px);transform:translateX(0px)}to{-webkit-transform:translateX(45px);transform:translateX(45px)}}
.services__wrapper{min-height:50vh;background-image:url(/static/media/03.54956f8a.webp);background-size:cover;background-position:center;position:relative}.service__title{color:#fff;font-weight:normal;background-color:rgba(0,0,0,.363);position:absolute;top:0;left:0;right:0;bottom:0;margin:0;padding:0;display:flex;align-items:center;justify-content:center}.sevices__search{border:1px solid rgba(0,0,0,.534);padding:.5rem 1.5rem;width:55%}.sevices__search:focus{outline:none !important}@media only screen and (max-width: 768px){.sevices__search{width:100%}}.search-button{padding:.5rem 1.5rem;height:39px}
.fHeader__wrapper{font-family:"Montserrat",sans-serif !important;background:#fd602c !important;font-size:14px !important}.fHeader__rightText{color:#fff;transition:300ms !important}.fHeader__rightText:hover{cursor:pointer;color:#000 !important;transition:300ms !important}
.header__wrapper{font-family:"Montserrat",sans-serif !important;display:flex;align-items:center;justify-content:space-between}.header__navbar{display:flex;align-items:center}.header__navbarText{padding:0px 10px;font-weight:400;color:#8d77a3;font-size:14px;transition:300ms !important}.header__navbarText:hover{cursor:pointer;transition:300ms !important}.header__navbarText:hover .header__navbar-dropdown{transition:all .5s ease-in;display:block}@media only screen and (max-width: 1126px){.header__navbar{display:none}.header__wrapper{justify-content:space-between}.header__navbar_mob{display:block}}@media only screen and (min-width: 1126px){.header__navbar_mob{display:none}}.header__navbar-dropdown{background-color:#fff;min-width:100%;width:-webkit-max-content;width:max-content;top:100%;z-index:100;padding-top:1.25rem;display:none;transition:all .5s}.header__navbar-dropdown-item{cursor:pointer;transition:.5s}.header__navbar-dropdown-item:hover{background-color:rgba(211,211,211,.322);transition:.5s}.header__nav-fixed{position:fixed;width:100%;top:0;z-index:888;background-color:#fff}
.footer__wrapper{background:#000}.footer__wrapperBott{background:#1a1a1a;color:#727272;font-family:"Montserrat",sans-serif !important}.footer__frstRow{color:#727272}.footer__headings{color:#fff;font-family:"Montserrat",sans-serif;font-size:22px}.footer__recentPostsWrapper{font-family:"Montserrat",sans-serif !important}.footer__recentPostsWrapper:hover{cursor:pointer}.footer__headings__style{border:1px solid #fff}.footer__recentPostTitle{font-size:18px}.footer__recentPostSubTitle{font-size:14px;color:#727272}.footer__recentPostAuthor{font-size:14px;color:#727272}.footer__usefullLinks{font-family:"Montserrat",sans-serif !important;font-size:14px;margin-left:10px;transition:300ms;color:#727272}.footer__usefullLinks:hover{cursor:pointer;transition:300ms;color:#fff}.footer__tagBox{font-family:"Montserrat",sans-serif !important;color:#727272;border:1px solid #727272}.footer__tagBox:hover{cursor:pointer;transition:400ms;border:1px solid #fd602c;color:#fd602c}.footer__bottomIcons{display:flex;align-items:center;justify-content:center;width:40px;height:40px;background:#fd602c}.footer__bottomIcons:hover{cursor:pointer}.footer__recentPostsWrapper_img{height:60px;width:60px;object-fit:cover;object-position:center}
.eventlist__search-lable{font-size:1em}.eventlist__search-input{width:100%}.eventlist__search-resultbox-item-wrapper{border-bottom:1px solid #fff;padding-bottom:.5rem;cursor:pointer;transition:all .3s;z-index:999 !important}.eventlist__search-resultbox-item-wrapper:hover{color:#fd602c;transition:all .3s}.eventlist__search-resultbox{margin-top:.3rem;top:100%;width:100%;padding:.4rem;background-color:#fff;border-radius:5px;max-height:300px;overflow-y:auto;z-index:999 !important;box-shadow:1px 2px 20px rgba(0,0,0,.527)}.eventlist__search-resultbox-img{height:50px;width:50px;border-radius:50%;object-fit:cover;border:1px solid rgba(128,128,128,.164)}
.servicecard__wrapper{background-color:#fff;padding:1rem;border-radius:.5rem}.servicecard__price{font-weight:500;font-size:1.1em;color:#000}.servicecard__title{margin:0;padding:0;font-weight:600;color:#fd602c;font-size:20px}
